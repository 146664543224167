import type { ButtonHTMLAttributes, PointerEvent } from "react";

import { Icon } from "../../../atoms/Icon";
import { sizes } from "../../../design-tokens/dimensions";
import type { flatIcons } from "../../../design-tokens/icons";
import { Root } from "./styles";

export type SuffixIconProps = {
  /**
   * Icon name from the allowed list.
   */
  icon: keyof typeof flatIcons;
  /**
   * Action to perform when the button is clicked.
   */
  onClick: (e: PointerEvent<HTMLButtonElement>) => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Renders a {@link SuffixIcon} for use in the {@link Input} interface
 */
export function SuffixIcon({ icon, onClick, ...rest }: SuffixIconProps): JSX.Element {
  return (
    <Root
      variant="inline"
      onClick={onClick}
      before={<Icon icon={icon} height={sizes.s16.rem} style={{ transform: "none" }} />}
      data-testid={`Input${SuffixIcon.name}`}
      {...rest}
    ></Root>
  );
}
