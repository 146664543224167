import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";

import { InputControl, LabelContainer, LabelText, Root } from "./styles";
import type { SuffixIconProps } from "./SuffixIcon";
import { SuffixIcon } from "./SuffixIcon";

type LabelledInputProps = {
  /**
   * The label for the field.
   */
  label: string;
  /**
   * Adds a suffix icon for the input field.
   */
  suffixIcon?: SuffixIconProps;
} & Omit<ComponentPropsWithoutRef<typeof InputControl>, "placeholder" | "suffixIcon">;

/**
 * A labelled general purpose input field.
 */
export function Input({
  label,
  value,
  defaultValue,
  onBlur,
  suffixIcon,
  ...rest
}: LabelledInputProps): JSX.Element {
  const isControlled = value !== undefined;
  const [isEmpty, setIsEmpty] = useState(`${value ?? defaultValue ?? ""}`.length === 0);
  const { disabled } = rest;

  // Check for an empty input via the value prop when it is being used (controlled).
  useEffect(() => {
    if (isControlled) {
      setIsEmpty(`${value}`.length === 0);
    }
  }, [isControlled, value]);

  // Check for an empty input via a blur event when the value is not being used (uncontrolled).
  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!isControlled) {
      setIsEmpty(event.target.value.length === 0);
    }

    onBlur && onBlur(event);
  };

  return (
    <Root>
      <LabelContainer data-testid={Input.name} displaceLabel={!isEmpty}>
        <LabelText data-testid={`${Input.name}-LabelText`} {...rest}>
          {label}
        </LabelText>
        <InputControl
          value={value}
          defaultValue={defaultValue}
          hasSuffix={!!suffixIcon}
          {...rest}
          onBlur={handleBlur}
        />
        {suffixIcon && <Input.SuffixIcon disabled={disabled} {...suffixIcon} />}
      </LabelContainer>
    </Root>
  );
}

/**
 * Composite component structure
 */
Input.SuffixIcon = SuffixIcon;
